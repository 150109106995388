<template>
    <div id="misc">
     
      <div class="page-title text-center px-5">

        <v-flex  class="d-xs-inline d-sm-none d-md-none d-lg-none d-xl-none" xs12 mb-1 ml-1>
            <v-text-field 
                v-model="search"
                append-icon="search"
                label="Busqueda"
                single-line
                hide-details
            ></v-text-field>
            </v-flex>  

        <v-flex  class="d-xs-inlin d-sm-inline d-md-none d-lg-none d-xl-none" xs12 mb-1 ml-1>
                        <v-checkbox
                        v-model="checkboxTodo"
                        label="Seleccionar todo"
                        @change="seleccionarTodo()"
                        ></v-checkbox>
                    </v-flex>

        <v-data-table
                :headers="headers"
                :items="arrayVehiculos"
                :search="search"
                height="420"
                fixed-header
                class="elevation-1"
            >

            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title  class="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex">Vehiculos a visualizar</v-toolbar-title>
                    <v-divider  class="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex" inset vertical></v-divider>
                    <v-spacer></v-spacer>

                    <v-flex mx-1 pt-4>
                        <v-btn dark color="success" @click="GuardarVisible()" class="mx-1">
                            <v-icon>save</v-icon>Guardar visibles ( {{selected.length}} )
                         </v-btn>
                     </v-flex>

                    <v-flex mx-1 pt-4>
                         <v-btn dark color="info" @click="listarVehiculos()" class="mx-1">
                            <v-icon>refresh</v-icon>
                         </v-btn> 
                    </v-flex>

                    <v-flex  class="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex" xs8 md8 lg4 xl4 mb-1 ml-1>

                    <v-text-field 
                        v-model="search"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-flex>  


                    <v-flex xs2 sm2 md2 lg2 xl2 class="mt-4 ml-2 d-none d-sm-none d-md-flex d-lg-flex d-xl-flex">
                        <v-checkbox
                        v-model="checkboxTodo"
                        label="Seleccionar todo"
                        @change="seleccionarTodo()"
                        ></v-checkbox>
                    </v-flex>

                </v-toolbar>
            </template>

            <template v-slot:[`item.visible`]="{ item }">
            <v-checkbox density="compact"
                v-model="item.visible"
                @click="cambioVisible(item)"
            ></v-checkbox>    
            </template>

    </v-data-table>
      </div>
    </div>
  </template>
  
  <script>

import axios from 'axios';

import { mapState,mapMutations } from "vuex";
 


export default {
      components:{
        
    
         },
    data () {
      return {
        selected: [],
        headers: [
            { text:'visible', value:'visible'},
            { text: 'Nombre', value: 'nombre' },
            { text: 'Placa', value: 'placa' },
            { text: 'Flota', value: 'flota' }
        ],
        arrayVehiculos: [],
        search:'',
        checkboxTodo:false,
          
      
    }
    },
     computed:{
        ...mapState(['empresaid', 'token','usuario']),

    },watch:{

    },

     created () {
       
        this.listarVehiculos();
    },

    mounted(){

    },
    methods:{ 

        ...mapMutations(['mostrarLoading','ocultarLoading']),

        listarVehiculos(){

                         this.arrayVehiculos=[];
                         this.selected=[];
                          
                          let me=this;
                          let config={headers:{token:this.token}};
                           
                           axios.get(`vehiculos-tercerizadosusr/${this.usuario.id_tercerizado}`,
                           config
                           ).then(function(response){

                              console.log(response.data);
                              me.arrayVehiculos=response.data;

                              me.arrayVehiculos.map(function(x){
                                if(x.visible==true){
                                    me.selected.push(x._id);
                                   
                                }
                              });

                             
                             
                           }).catch(function(error){ console.log(error); });

        },

        GuardarVisible(){


            this.mostrarLoading({titulo:'accediendo a datos'});
            let config={headers:{token:this.token}};
            axios.post('actualizar-visibles',
                    { 
                      'id_tercerizado':this.usuario.id_tercerizado,
                      'array': this.selected
                    },
              config
          ).then(response=>{
            console.log(response);
            this.listarVehiculos();

            this.ocultarLoading();

          }).catch(e=>{
                console.log(e)
          });

        },

        cambioVisible(item){

            if(item.visible==true){  
                this.selected.push(item._id);
            }else{
                let editedIndex = this.selected.indexOf(item._id);
                this.selected.splice(editedIndex, 1);
            }


        },

        seleccionarTodo(){

            let me=this;

            me.selected=[];

            if(this.checkboxTodo==true){

                me.arrayVehiculos.map(function(x){
                                    x.visible=true;
                                    me.selected.push(x._id);    
                              });

            }else{

                me.arrayVehiculos.map(function(x){
                                    x.visible=false;
                  });
               me.selected=[]; 
            }
    
    }


    }
  }


  </script>
  